import React, { StrictMode } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import metadata from '../../content/components/metadata.yml';
import HeaderForToolkit from '@/components/HeaderForToolkit';
import FooterForToolkit from '@/components/FooterForToolkit';

const Main = styled.main`
  background-color: rgb(244, 247, 249);
`;

interface MetadataContent {
  title: string;
  icon: string;
}

const Layout: React.FunctionComponent = ({ children }) => {
  const { title, icon } = metadata as unknown as MetadataContent;
  return (
    <StrictMode>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="icon" type="image/svg" sizes="32x32" href={icon} />
        <link rel="canonical" href="https://fena.co" />
      </Helmet>
      <HeaderForToolkit />
      <Main>{children}</Main>
      <FooterForToolkit />
    </StrictMode>
  );
};

export default Layout;

import React from 'react';
import styled from 'styled-components';

const Circle = styled.span<{ isSmall?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  margin: 0.25rem;
  border-radius: 50%;
  overflow: hidden;
  width: ${({ isSmall }) => (isSmall ? `30px` : `36px`)};
  height: ${({ isSmall }) => (isSmall ? `30px` : `36px`)};
`;

const SVG = styled.img``;

interface CustomIconProps {
  src: string;
  small?: boolean;
}

const CustomIcon: React.FunctionComponent<CustomIconProps> = ({
  src,
  small,
}) => (
  <div>
    <Circle isSmall={small}>
      <SVG src={src} alt="custom icon" />
    </Circle>
  </div>
);

export default CustomIcon;
